import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
	type ReactNode,
} from 'react';
import difference from 'lodash/difference';
import {
	fetchWorkflowIssues,
	Unchanged,
	type WorkflowIssuesData,
} from '@atlassian/jira-business-board-workflow-issues/src/index.tsx';
import type { Workflow } from '@atlassian/jira-business-board-workflow-issues/src/types.tsx';
import { useProject } from '@atlassian/jira-business-entity-project-hook/src/index.tsx';
import { ACTIVE_WORKFLOW_PREFERENCE } from '@atlassian/jira-business-preferences/src/constants.tsx';
import { useViewPreference } from '@atlassian/jira-business-preferences/src/controllers/view-preferences-context/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useFlagsService, type FlagId } from '@atlassian/jira-flags';
import { useWorkflowIssues } from '@atlassian/jira-router-resources-business-board-workflow-issues/src/index.tsx';
import { ISSUE_TYPE_ID } from '../../common/constants.tsx';
import type { BoardIssue } from '../../common/types.tsx';
import { useFilter, useFilterStatus } from '../board-filters/index.tsx';
import { useIssueStoreActions } from '../board-issue-store/index.tsx';
import { useIssues } from '../board-issue-store/selectors/index.tsx';
import { mapWorkflowIssuesToBoardIssues, sortIssuesBy } from '../board-issue-store/utils.tsx';
import { useFieldIds } from '../field-ids/index.tsx';
import { useRefetchIssuesWithThrow } from '../refetch-issues/index.tsx';
import { useWorkflowStoreState, useWorkflowStoreActions } from '../workflow-store/index.tsx';
import messages from './messages.tsx';

const FETCH_FAILED_FLAG_ID: FlagId = 'FETCH_BOARD_DATA_FAILED';

const getActiveWorkflow = (workflows: Workflow[], activeWorkflowId?: string): Workflow => {
	const activeWorkflow =
		workflows.find((workflow) => workflow.workflowId === activeWorkflowId) ?? workflows[0];

	return activeWorkflow;
};

type RefetchFunction = () => Promise<void>;
type ResetFunction = () => void;
type Context = {
	filteredIssues: BoardIssue[];
	refetch: RefetchFunction;
	reset: ResetFunction;
	loading: boolean;
	error?: Error | null;
};

const Context = createContext<Context | null>(null);

export const BoardDataProvider = ({ children }: { children: ReactNode }) => {
	const { data, loading: isWorkflowLoading, error } = useWorkflowIssues();
	const project = useProject();
	const { workflowIssuesLastChangedTime } = useWorkflowStoreState();
	const { setFetchResponse } = useWorkflowStoreActions();
	const { showFlag, dismissFlag } = useFlagsService();
	const [activeWorkflowId] = useViewPreference(ACTIVE_WORKFLOW_PREFERENCE);
	const { updateIssues, reset: resetIssueStore } = useIssueStoreActions();
	const refetchIssuesWithThrow = useRefetchIssuesWithThrow();
	const fieldIds = useFieldIds();
	const [initialized, setInitialized] = useState(false);
	const fetchedFields = useRef<string[]>([]);
	const filterIssues = useFilter();
	const { isLoading: isLoadingFilters } = useFilterStatus();

	const allIssues = useIssues();
	const filteredIssues = useMemo(() => {
		const withoutSubtasks = Array.from(allIssues.values()).filter(
			(issue) => issue.fields[ISSUE_TYPE_ID].issueType.hierarchyLevel >= 0,
		);
		const issues = filterIssues(withoutSubtasks);

		return sortIssuesBy('rank', issues);
	}, [allIssues, filterIssues]);

	const reset = useCallback(() => {
		resetIssueStore();
		setInitialized(false);
		fetchedFields.current = [];
	}, [resetIssueStore]);

	const showErrorFlag = useCallback(() => {
		showFlag({
			id: FETCH_FAILED_FLAG_ID,
			type: 'error',
			title: messages.boardInitErrorTitle,
			description: messages.boardInitErrorMessage,
		});
	}, [showFlag]);

	useEffect(() => {
		if (!initialized) {
			if (data) {
				setInitialized(true);

				const activeWorkflowData = getActiveWorkflow(data.workflows, activeWorkflowId);

				if (!activeWorkflowData) {
					return;
				}

				const mappedIssues = mapWorkflowIssuesToBoardIssues(activeWorkflowData);
				updateIssues(mappedIssues);

				setFetchResponse({
					activeWorkflow: activeWorkflowData,
					workflows: data.workflows,
					workflowIssuesLastChangedTime: data.lastChangedTime || undefined,
				});
			} else if (error) {
				setInitialized(true);
				showErrorFlag();
			}
		}
	}, [activeWorkflowId, data, error, initialized, setFetchResponse, updateIssues, showErrorFlag]);

	useEffect(() => {
		// if there are additional fields required to be fetched, refetch the issues
		if (
			initialized &&
			!isLoadingFilters &&
			fieldIds.length > 0 &&
			difference(fieldIds, fetchedFields.current).length > 0
		) {
			fetchedFields.current = fieldIds;
			refetchIssuesWithThrow(Array.from(allIssues.keys())).catch(showErrorFlag);
		}
	}, [fieldIds, initialized, isLoadingFilters, allIssues, refetchIssuesWithThrow, showErrorFlag]);

	const refetch = useCallback(async () => {
		try {
			const response: WorkflowIssuesData = await fetchWorkflowIssues(
				project.key,
				workflowIssuesLastChangedTime,
			);

			const activeWorkflowData = getActiveWorkflow(response.workflows, activeWorkflowId);

			if (!activeWorkflowData) {
				return;
			}

			await refetchIssuesWithThrow(
				activeWorkflowData.issues.map((issue) => issue.id),
				true,
			);

			setFetchResponse({
				activeWorkflow: activeWorkflowData,
				workflows: response.workflows,
				workflowIssuesLastChangedTime: response.lastChangedTime || undefined,
			});
			dismissFlag(FETCH_FAILED_FLAG_ID);

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (refetchError: any) {
			if (refetchError instanceof Unchanged) {
				dismissFlag(FETCH_FAILED_FLAG_ID);
				return;
			}

			fireErrorAnalytics({
				meta: {
					id: 'refetchBoardData',
					packageName: 'jiraWorkManagementBoard',
					teamName: 'wanjel',
				},
				attributes: {
					message: 'Failed to refetch workflow issues data',
				},
				error: refetchError,
				sendToPrivacyUnsafeSplunk: true,
			});
			showErrorFlag();
		}
	}, [
		project.key,
		workflowIssuesLastChangedTime,
		activeWorkflowId,
		refetchIssuesWithThrow,
		setFetchResponse,
		dismissFlag,
		showErrorFlag,
	]);

	// only mark as loading when the page initialises but not when applying filters thereafter
	const [loading, setLoading] = useState(isWorkflowLoading || isLoadingFilters);
	useEffect(() => {
		if (loading && !isWorkflowLoading && !isLoadingFilters) {
			setLoading(false);
		}
	}, [loading, isWorkflowLoading, isLoadingFilters]);

	const value = useMemo(
		() => ({
			filteredIssues,
			refetch,
			reset,
			// we want to show loading state for server side rendering
			// eslint-disable-next-line jira/ff/no-preconditioning
			loading: fg('sv-2_convert_board_view_to_entrypoints') && __SERVER__ ? true : loading,
			error,
		}),
		[filteredIssues, refetch, reset, error, loading],
	);

	return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useBoardData = (): Context => {
	const context = useContext(Context);

	if (!context) {
		throw new Error('useInitializeBoard must be used within a BoardDataProvider');
	}

	return context;
};
