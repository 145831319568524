import React, { useState, useCallback, memo, useEffect } from 'react';
import Popup from '@atlaskit/popup';
import { fg } from '@atlassian/jira-feature-gating';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import type { QuickFilterKeys } from '../../common/types.tsx';
import { useGoalsFilterAnalyticsEvent } from '../../common/utils/goals-filter-analytics-event/index.tsx';
import { useDeserialiseJqlFiltersWithContext } from '../../controllers/deserialise-filters/index.tsx';
import { useAllowedFieldTypes } from '../../controllers/filters-context/index.tsx';
import { useQuickFilters } from '../../controllers/quick-filters/index.tsx';
import { MenuContent } from './menu-content/index.tsx';
import { Trigger } from './trigger/index.tsx';
import type { Props } from './types.tsx';

const hiddenQuickFiltersDefault: QuickFilterKeys[] = ['ACTIVE_SPRINTS'];

export const Filters = memo(
	({
		view,
		allowedFieldTypes: inputAllowedFieldTypes,
		allowedAliasFieldId,
		hiddenQuickFilters = hiddenQuickFiltersDefault,
		allowCustomFields,
		allowSavedFilters = true,
		handleHasFiltersApplied,
		appearance,
	}: Props) => {
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const allowedFieldTypes = useAllowedFieldTypes(inputAllowedFieldTypes);
		const [isOpen, setIsOpen] = useState(false);
		const close = useCallback(() => {
			setIsOpen(false);
		}, []);

		const toggle = useCallback(() => {
			setIsOpen((prevIsOpen) => !prevIsOpen);

			fireUIAnalytics(createAnalyticsEvent({}), 'jwmFilterButton clicked');
		}, [createAnalyticsEvent]);

		const { quickFilters, persistQuickFilters: setQuickFilters } = useQuickFilters();
		const { filters, fieldConfig, customFieldsLoader, hasFilterError, setFilters } =
			useDeserialiseJqlFiltersWithContext({
				view,
				allowedFieldTypes,
				allowedAliasFieldId,
				allowCustomFields,
			});

		const count =
			Object.values(filters).flat().length +
			Object.values(quickFilters).filter((value) => value).length;

		useEffect(() => {
			if (handleHasFiltersApplied) {
				handleHasFiltersApplied(count);
			}
		}, [count, handleHasFiltersApplied]);

		if (fg('native_goals_integration_on_list_view')) {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			useGoalsFilterAnalyticsEvent({ filters, view });
		}

		const hasFiltersApplied = count > 0 || hasFilterError;

		return (
			<>
				<UFOSegment name="business-filters">
					<Popup
						isOpen={isOpen}
						onClose={close}
						placement="bottom-end"
						content={() => (
							<>
								<MenuContent
									data-testid="business-filters.ui.filters.menu-content"
									count={count}
									fieldConfig={fieldConfig}
									filters={filters}
									quickFilters={quickFilters}
									setQuickFilters={setQuickFilters}
									setFilters={setFilters}
									hiddenQuickFilters={hiddenQuickFilters}
									view={view}
									allowCustomFields={allowCustomFields}
									allowSavedFilters={allowSavedFilters}
									hasFilterError={hasFilterError}
								/>
							</>
						)}
						trigger={(triggerProps) => (
							<Trigger
								{...triggerProps}
								{...(fg('jira-calendar-business-theme') && { appearance })}
								count={count}
								hasFiltersApplied={hasFiltersApplied}
								isSelected={isOpen}
								onClick={toggle}
							/>
						)}
					/>
					{customFieldsLoader}
				</UFOSegment>
			</>
		);
	},
);
