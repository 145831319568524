// The Workflow V2 API is defined here: https://developer.atlassian.com/cloud/jira-workflow/rest/api-group-workflow-v-/

import { useCallback } from 'react';
import uuid from 'uuid/v4';
import { IN_PROGRESS } from '@atlassian/jira-common-constants/src/status-categories.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	NO_INBOUND_TRANSITIONS_TO_STATUS,
	NON_UNIQUE_STATUS_NAME,
	VERSION_CONFLICT,
	OLD_STATUS_MISSING_IN_ORIGINAL_WORKFLOW,
	NEW_STATUS_IN_MAPPING_MISSING_IN_WORKFLOW,
	ONLY_ONE_INITIAL_TRANSITION_ALLOWED,
	APPROVAL_RULE_MISCONFIGURED,
} from './constants.tsx';
import {
	StatusNameIsNotUniqueError,
	StatusHasNoIncomingTransitionError,
	WorkflowVersionConflictError,
	OldStatusMissingInWorkflowError,
	NewStatusMissingInWorkflowError,
	OnlyOneInitialTransitionAllowedError,
	ApprovalRuleMisconfiguredError,
	TranslatedValidationError,
} from './errors/index.tsx';
import type {
	FetchWorkflowsV2Param,
	WorkflowV2Response,
	WorkflowV2UpdatePayload,
	WorkflowUpdateV2Response,
	WorkflowValidationErrorList,
	WorkflowV2UpdateValidationRequest,
	WorkflowV2CreateStatusParams,
	StatusUpdate,
	Transition,
	WorkflowStatus,
	SingleWorkflowCreateStatusParams,
} from './types.tsx';
import {
	removeWorkflowAssociatedWithSubtaskOnly,
	mapStatusesToStatusUpdateObjects,
	mapWorkflowToWorkflowUpdateObject,
	getUniqueTransitionId,
} from './utils.tsx';

// Replace with lodash/noop
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

export const useWorkflowsV2 = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fetchWorkflowsV2 = useCallback(
		async ({
			cloudId,
			projectAndIssueTypes,
		}: FetchWorkflowsV2Param): Promise<WorkflowV2Response> => {
			const url = `/gateway/api/jira/project-configuration/query/${cloudId}/2/workflow?useTransitionLinksFormat=true`;
			try {
				const response = await fetchJson<WorkflowV2Response>(url, {
					method: 'POST',
					body: JSON.stringify({
						projectAndIssueTypes,
					}),
				});

				return response;
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				const jsonErrorResponse = error.originalResponse
					? await error.originalResponse.json().catch(noop)
					: undefined;

				if (jsonErrorResponse) {
					const errorMessage = `Fetch workflow fail status code: ${
						error.statusCode
					}; response: ${JSON.stringify(jsonErrorResponse)}`;

					const errorWithResponseMessage = new FetchError(
						error.statusCode,
						errorMessage,
						error.traceId,
					);

					fireErrorAnalytics({
						meta: {
							id: 'fetchBoardWorkflowsV2',
							packageName: 'jiraBusinessWorkflows',
							teamName: 'wanjel',
						},
						error: errorWithResponseMessage,
						sendToPrivacyUnsafeSplunk: true,
					});

					throw errorWithResponseMessage;
				}

				fireErrorAnalytics({
					meta: {
						id: 'fetchBoardWorkflowsV2',
						packageName: 'jiraBusinessWorkflows',
						teamName: 'wanjel',
					},
					attributes: {
						message: 'Failed to fetch board workflows',
					},
					error,
					sendToPrivacyUnsafeSplunk: true,
				});

				throw error;
			}
		},
		[],
	);
	/**
	 * Update a single workflow only
	 * The `workflow` array in the payload can only have maximum 1 length
	 */
	const updateWorkflowsV2 = useCallback(
		async ({ payload, cloudId }: { payload: WorkflowV2UpdatePayload; cloudId: string }) => {
			try {
				const response = await fetchJson<WorkflowUpdateV2Response>(
					`/gateway/api/jira/project-configuration/command/${cloudId}/2/workflow/update`,
					{
						method: 'POST',
						body: JSON.stringify(payload),
					},
				);
				return response;
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				const jsonErrorResponse = error.originalResponse
					? await error.originalResponse.json().catch(noop)
					: undefined;

				if (jsonErrorResponse) {
					if (
						// eslint-disable-next-line jira/ff/no-preconditioning
						fg('business_create_status_column_validation_error') &&
						error.statusCode === 400 &&
						jsonErrorResponse.errors?.[0].message
					) {
						throw new TranslatedValidationError(jsonErrorResponse.errors[0].message);
					}

					const errorMessage = `Update workflow fail status code: ${
						error.statusCode
					}; response: ${JSON.stringify(jsonErrorResponse)}`;

					const errorWithResponseMessage = new FetchError(
						error.statusCode,
						errorMessage,
						error.traceId,
					);

					fireErrorAnalytics({
						meta: {
							id: 'updateBoardWorkflowsV2',
							packageName: 'jiraBusinessWorkflows',
							teamName: 'wanjel',
						},
						error: errorWithResponseMessage,
						sendToPrivacyUnsafeSplunk: true,
					});

					throw errorWithResponseMessage;
				}

				fireErrorAnalytics({
					meta: {
						id: 'updateBoardWorkflowsV2',
						packageName: 'jiraBusinessWorkflows',
						teamName: 'wanjel',
					},
					attributes: {
						message: 'Failed to update board workflows',
					},
					error,
					sendToPrivacyUnsafeSplunk: true,
				});

				throw error;
			}
		},
		[],
	);

	/**
	 * Validates the update payload and calls update workflow if there are no validation errors
	 * The `workflow` array in the payload can only have maximum 1 length
	 */
	const validateAndUpdateWorkflowsV2 = useCallback(
		async ({
			payload,
			cloudId,
		}: {
			payload: WorkflowV2UpdateValidationRequest;
			cloudId: string;
		}): Promise<WorkflowUpdateV2Response> => {
			// validate payload
			let validationResponse: WorkflowValidationErrorList;
			try {
				validationResponse = await fetchJson<WorkflowValidationErrorList>(
					`/gateway/api/jira/project-configuration/command/${cloudId}/2/workflow/update/validation`,
					{
						method: 'POST',
						body: JSON.stringify(payload),
					},
				);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				const jsonErrorResponse = error.originalResponse
					? await error.originalResponse.json().catch(noop)
					: undefined;

				if (jsonErrorResponse) {
					const errorMessage = `Validate workflow fail status code: ${
						error.statusCode
					}; response: ${JSON.stringify(jsonErrorResponse)}`;

					const errorWithResponseMessage = new FetchError(
						error.statusCode,
						errorMessage,
						error.traceId,
					);

					fireErrorAnalytics({
						meta: {
							id: 'validateUpdateBoardWorkflowsV2',
							packageName: 'jiraBusinessWorkflows',
							teamName: 'wanjel',
						},
						error: errorWithResponseMessage,
						sendToPrivacyUnsafeSplunk: true,
					});

					throw errorWithResponseMessage;
				}

				fireErrorAnalytics({
					meta: {
						id: 'validateUpdateBoardWorkflowsV2',
						packageName: 'jiraBusinessWorkflows',
						teamName: 'wanjel',
					},
					attributes: {
						message: 'Failed to validate payload for update workflows',
					},
					error,
					sendToPrivacyUnsafeSplunk: true,
				});

				throw error;
			}

			if (validationResponse.errors.length > 0) {
				validationResponse.errors.forEach((e) => {
					if (e.code === NON_UNIQUE_STATUS_NAME) {
						fireTrackAnalytics(createAnalyticsEvent({}), 'workflowValidation failed', {
							message: e.message,
							code: e.code,
						});
						throw new StatusNameIsNotUniqueError();
					}

					if (e.code === NO_INBOUND_TRANSITIONS_TO_STATUS) {
						fireTrackAnalytics(createAnalyticsEvent({}), 'workflowValidation failed', {
							message: e.message,
							code: e.code,
						});
						throw new StatusHasNoIncomingTransitionError();
					}

					if (e.code === OLD_STATUS_MISSING_IN_ORIGINAL_WORKFLOW) {
						throw new OldStatusMissingInWorkflowError();
					}

					if (e.code === NEW_STATUS_IN_MAPPING_MISSING_IN_WORKFLOW) {
						throw new NewStatusMissingInWorkflowError();
					}

					if (e.code === ONLY_ONE_INITIAL_TRANSITION_ALLOWED) {
						throw new OnlyOneInitialTransitionAllowedError();
					}

					if (e.code === APPROVAL_RULE_MISCONFIGURED) {
						throw new ApprovalRuleMisconfiguredError();
					}

					if (e.code === VERSION_CONFLICT) {
						throw new WorkflowVersionConflictError();
					}
				});

				const errorMsgs = validationResponse.errors.map((e) => e.message).join(', ');
				const errorCodes = validationResponse.errors.map((e) => e.code).join(', ');
				const errorMessage = `Validation error(s) in update workflows payload - ${errorMsgs}`;
				const error = Error(errorMessage);

				fireErrorAnalytics({
					meta: {
						id: 'validateUpdateBoardWorkflowsV2',
						packageName: 'jiraBusinessWorkflows',
						teamName: 'wanjel',
					},
					attributes: {
						message: errorMessage,
						totalErrors: validationResponse.errors.length,
						code: errorCodes,
					},
					error,
					sendToPrivacyUnsafeSplunk: true,
				});

				throw error;
			}

			// update workflow
			return updateWorkflowsV2({
				payload: payload.payload,
				cloudId,
			});
		},
		[updateWorkflowsV2, createAnalyticsEvent],
	);

	const createWorkflowStatusV2 = useCallback(
		async ({
			statusName,
			statusCategory,
			cloudId,
			projectId,
			issueTypes,
			onSuccess,
		}: WorkflowV2CreateStatusParams) => {
			const workflowsData = await fetchWorkflowsV2({
				cloudId,
				projectAndIssueTypes: issueTypes.map((it) => ({
					projectId,
					issueTypeId: it.id,
				})),
			});

			if (workflowsData) {
				const subtaskIssueTypeId = issueTypes.find(
					(issueType) => issueType.hierarchyLevel === -1,
				)?.id;

				// Remove workflows associated with one subtask issue type only
				const { statuses, workflows: boardWorkflows } = removeWorkflowAssociatedWithSubtaskOnly(
					workflowsData,
					subtaskIssueTypeId,
				);
				const workflow = boardWorkflows[0];

				const transitionId = getUniqueTransitionId(workflow.transitions);

				// validate if the new transition name is unique
				const isTransitionNameExists = workflow.transitions.some(
					(t) => t.name.toUpperCase() === statusName.toUpperCase(),
				);
				const newTransitionName = isTransitionNameExists
					? `${statusName} ${transitionId}`
					: statusName;

				const statusReference = uuid();

				const newStatus: StatusUpdate = {
					statusReference,
					name: statusName,
					statusCategory: statusCategory ?? IN_PROGRESS,
					description: '',
				};
				const newStatusInWorkflow: WorkflowStatus = {
					statusReference,
					properties: {},
					deprecated: false,
				};

				const transitionData = {
					links: [],
					toStatusReference: statusReference,
				};

				const newTransitionInWorkflow: Transition = {
					id: transitionId,
					type: 'GLOBAL',
					name: newTransitionName,
					description: '',
					actions: [],
					validators: [],
					triggers: [],
					properties: {},
					...transitionData,
				};

				// append newly created status and transition into workflow data
				workflow.statuses = [...workflow.statuses, newStatusInWorkflow];
				workflow.transitions = [...workflow.transitions, newTransitionInWorkflow];

				await validateAndUpdateWorkflowsV2({
					cloudId,
					payload: {
						payload: {
							statuses: [...mapStatusesToStatusUpdateObjects(statuses), newStatus],
							workflows: [mapWorkflowToWorkflowUpdateObject(workflow)],
						},
						validationOptions: { levels: ['ERROR'] },
					},
				});
				onSuccess();
			}
		},
		[fetchWorkflowsV2, validateAndUpdateWorkflowsV2],
	);

	const createWorkflowStatusforSingleWorkflow = useCallback(
		async ({
			statusName,
			statusCategory,
			cloudId,
			projectId,
			issueTypeId,
			onSuccess,
		}: SingleWorkflowCreateStatusParams) => {
			const workflowsData = await fetchWorkflowsV2({
				cloudId,
				projectAndIssueTypes: [{ projectId, issueTypeId }],
			});

			if (workflowsData) {
				const { statuses, workflows } = workflowsData;
				const workflow = workflows[0];

				const transitionId = getUniqueTransitionId(workflow.transitions);

				// validate if the new transition name is unique
				const isTransitionNameExists = workflow.transitions.some(
					(t) => t.name.toUpperCase() === statusName.toUpperCase(),
				);
				const newTransitionName = isTransitionNameExists
					? `${statusName} ${transitionId}`
					: statusName;

				const statusReference = uuid();

				const newStatus: StatusUpdate = {
					statusReference,
					name: statusName,
					statusCategory: statusCategory ?? IN_PROGRESS,
					description: '',
				};
				const newStatusInWorkflow: WorkflowStatus = {
					statusReference,
					properties: {},
					deprecated: false,
				};

				const transitionData = {
					links: [],
					toStatusReference: statusReference,
				};

				const newTransitionInWorkflow: Transition = {
					id: transitionId,
					type: 'GLOBAL',
					name: newTransitionName,
					description: '',
					actions: [],
					validators: [],
					triggers: [],
					properties: {},
					...transitionData,
				};

				// append newly created status and transition into workflow data
				workflow.statuses = [...workflow.statuses, newStatusInWorkflow];
				workflow.transitions = [...workflow.transitions, newTransitionInWorkflow];

				await validateAndUpdateWorkflowsV2({
					cloudId,
					payload: {
						payload: {
							statuses: [...mapStatusesToStatusUpdateObjects(statuses), newStatus],
							workflows: [mapWorkflowToWorkflowUpdateObject(workflow)],
						},
						validationOptions: { levels: ['ERROR'] },
					},
				});
				onSuccess();
			}
		},
		[fetchWorkflowsV2, validateAndUpdateWorkflowsV2],
	);

	return {
		fetchWorkflowsV2,
		updateWorkflowsV2,
		validateAndUpdateWorkflowsV2,
		createWorkflowStatusV2,
		createWorkflowStatusforSingleWorkflow,
	};
};
