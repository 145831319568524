import type {
	ExperienceOnAbort,
	ExperienceOnFail,
} from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import {
	StatusNameIsNotUniqueError,
	StatusHasNoIncomingTransitionError,
	WorkflowVersionConflictError,
	OldStatusMissingInWorkflowError,
	NewStatusMissingInWorkflowError,
	OnlyOneInitialTransitionAllowedError,
	ApprovalRuleMisconfiguredError,
	TranslatedValidationError,
} from '@atlassian/jira-business-workflows/src/services/workflow-v2/errors/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { isValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { type FlagConfiguration, toFlagId } from '@atlassian/jira-flags';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { UFOExperience } from '@atlassian/ufo';
import messages from './messages.tsx';

type WorkflowError = {
	statusCode: number;
	message?: string;
};

const GENERIC_SAVING_ERROR = 'There was an error saving the workflows';
const excludedStatusCodes = new Set([401, 403, 404, 409]);

const isFilteredNetworkError = (error: WorkflowError | Error): boolean =>
	Boolean(
		('statusCode' in error &&
			error.statusCode != null &&
			excludedStatusCodes.has(error.statusCode)) ||
			error.message?.includes(GENERIC_SAVING_ERROR),
	);

export const getStatusActionFailedFlag = ({
	messageTitle,
	action,
	error,
	projectKey,
	issueTypeId,
	statusCode,
}: {
	messageTitle: MessageDescriptor;
	action: string;
	error?: Error;
	projectKey: string;
	issueTypeId: string | undefined;
	statusCode?: number;
}): FlagConfiguration => {
	const workflowEditorHref =
		issueTypeId != null && issueTypeId !== ''
			? `/jira/core/projects/${projectKey}/settings/issuetypes/${issueTypeId}/workflow`
			: `/jira/core/projects/${projectKey}/settings/issuetypes`;
	const openWorkflowEditorAction = {
		content: messages.openWorkflowEditorAction,
		href: workflowEditorHref,
		target: '_blank',
	};

	if (error instanceof StatusNameIsNotUniqueError) {
		return {
			type: 'error',
			title: messageTitle,
			description: messages.statusNameIsNotUniqueDescription,
			actions: [openWorkflowEditorAction],
			id: toFlagId(`business-${action}-status-name-is-not-unique.flag`),
		};
	}

	if (error instanceof StatusHasNoIncomingTransitionError) {
		return {
			type: 'error',
			title: messages.statusHasNoIncomingTransitionTitle,
			description: messages.statusHasNoIncomingTransitionDescription,
			actions: [openWorkflowEditorAction],
			id: toFlagId(`business-${action}-status-has-no-incoming-transition.flag`),
		};
	}

	if (error instanceof WorkflowVersionConflictError || statusCode === 409) {
		return {
			type: 'error',
			title: messageTitle,
			description: messages.workflowVersionConflictDescription,
			actions: [
				{
					content: messages.viewWorkflowEditorAction,
					href: workflowEditorHref,
					target: '_blank',
				},
			],
			id: toFlagId(`business-${action}-workflow-version-conflict.flag`),
		};
	}

	if (error instanceof OldStatusMissingInWorkflowError) {
		return {
			type: 'error',
			title: messageTitle,
			description: messages.oldStatusMissingInWorkflowDescription,
			actions: [openWorkflowEditorAction],
			id: toFlagId(`business-${action}-old-status-missing-in-workflow.flag`),
		};
	}

	if (error instanceof NewStatusMissingInWorkflowError) {
		return {
			type: 'error',
			title: messageTitle,
			description: messages.newStatusMissingInWorkflowDescription,
			actions: [openWorkflowEditorAction],
			id: toFlagId(`business-${action}-new-status-missing-in-workflow.flag`),
		};
	}

	if (error instanceof OnlyOneInitialTransitionAllowedError) {
		return {
			type: 'error',
			title: messageTitle,
			description: messages.oneInitialTransitionAllowedWorkflowDescription,
			actions: [openWorkflowEditorAction],
			id: toFlagId(`business-${action}-only-one-initial-transition-allowed.flag`),
		};
	}

	if (error instanceof ApprovalRuleMisconfiguredError) {
		return {
			type: 'error',
			title: messageTitle,
			description: messages.approvalRuleMisconfiguredDescription,
			actions: [openWorkflowEditorAction],
			id: toFlagId(`business-${action}-approval-rule-misconfigured.flag`),
		};
	}

	if (
		error instanceof TranslatedValidationError &&
		fg('business_create_status_column_validation_error')
	) {
		return {
			type: 'error',
			title: messageTitle,
			description: error.message,
			actions: [openWorkflowEditorAction],
		};
	}

	if (action === 'delete-polling') {
		return {
			type: 'warning',
			title: messageTitle,
			description: messages.deletePollingTimeoutDescription,
			actions: [openWorkflowEditorAction],
			id: toFlagId(`business-${action}-timeout.flag`),
		};
	}

	return {
		type: 'error',
		title: messageTitle,
		description: messages.errorFlagDescription,
		actions: [
			{
				content: messages.errorFlagActionsReload,
				onClick: () => {
					typeof window !== 'undefined' && window.location.reload();
				},
				target: '_blank',
			},
		],
		id: toFlagId(`business-${action}-status.flag`),
	};
};

export const failStatusExperience = ({
	markExperienceFailed,
	abortExperience,
	ufoExperienceFailure,
	ufoExperienceAbort,
	error,
	errorMessage,
}: {
	markExperienceFailed: ExperienceOnFail;
	abortExperience: ExperienceOnAbort;
	error: Error;
	errorMessage: string;
	ufoExperienceFailure: UFOExperience['failure'];
	ufoExperienceAbort: UFOExperience['abort'];
}) => {
	if (
		error instanceof StatusNameIsNotUniqueError ||
		error instanceof StatusHasNoIncomingTransitionError ||
		error instanceof WorkflowVersionConflictError ||
		error instanceof OldStatusMissingInWorkflowError ||
		error instanceof NewStatusMissingInWorkflowError ||
		error instanceof OnlyOneInitialTransitionAllowedError ||
		error instanceof ApprovalRuleMisconfiguredError ||
		(error instanceof TranslatedValidationError &&
			fg('business_create_status_column_validation_error')) ||
		isClientFetchError(error) ||
		isValidationError(error) ||
		isFilteredNetworkError(error)
	) {
		abortExperience(errorMessage);
		ufoExperienceAbort({ metadata: { errorMessage } });
		return;
	}

	markExperienceFailed(errorMessage, error);
	ufoExperienceFailure({ metadata: { errorMessage } });
};

export class WorkflowNotFound extends Error {
	constructor() {
		super();
		this.name = 'WorkflowNotFound';
		this.message = 'Workflow not found';
	}
}
